/**
 * External Dependencies
 */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Cookies from "js-cookie";

/**
 * Internal Dependencies
 */
// -> Auth
import AuthSignIn from "./pages/AuthSignIn";
import AuthSignUp from "./pages/AuthSignUp";
// import PrivacyPolicy from "./pages/PrivacyPolicy";

// -> Start
import Dashboard from "./pages/Dashboard";

// -> Operacional
// --> PDV
import PdvApp from "./pages/Operational/PdvApp";
import PdvAppPayment from "./pages/Operational/PdvApp/Payment";
import PdvBalcao from "./pages/Operational/PdvBalcao";
// --> Visualiza as vendas
import PDVShow from "./pages/Operational/PdvShow";
// --> Caixa
import Cashier from "./pages/Operational/Cashier";
// --> Relatório do dia de vendas e serviços
import PDVReport from "./pages/Operational/Report";
// --> Ranking de vendas e serviços
import PDVRanking from "./pages/Operational/Ranking";

// -> Financeiro
// --> Contas a pagar e a receber
import Flow from "./pages/Financial/Flow";
import FlowReceive from "./pages/Financial/FlowReceive";
import FlowPay from "./pages/Financial/FlowPay";
import FlowCommission from "./pages/Financial/Commission";

// --> Contas Bancárias
import BankAccount from "./pages/Financial/BankAccount";
import BankAccountCreate from "./pages/Financial/BankAccount/Create";
import BankAccountUpdate from "./pages/Financial/BankAccount/Update";
// --> Cartões de crédito
import CreditCard from "./pages/Financial/CreditCard";
import CreditCardCreate from "./pages/Financial/CreditCard/Create";
import CreditCardUpdate from "./pages/Financial/CreditCard/Update";

// -> CRM
// --> Clientes
import CRMClients from "./pages/CRM/Clients";
import CRMClientsCreate from "./pages/CRM/Clients/Create";
import CRMClientsUpdate from "./pages/CRM/Clients/Update";
import CRMClientsDetails from "./pages/CRM/Clients/Details";

// --> --> schedules
import CRMClientsSchedules from "./pages/CRM/Clients/Schedules";
import CRMClientsCalendar from "./pages/CRM/Clients/Calendar";

// --> schedules
import CRMCalendar from "./pages/CRM/Calendar";

// -> Relatórios
// --> Gerenciais
import ReportGerencialCashier from "./pages/Report/Gerencial/Cashier";
import ReportGerencialCommission from "./pages/Report/Gerencial/Commission";
import ReportGerencialRealizedServices from "./pages/Report/Gerencial/RealizedServices";
import ReportGerencialProductSold from "./pages/Report/Gerencial/ProductSold";

// -> Adminsitrations
// --> Filiais
import Filial from "./pages/ADM/Filial";
import FilialCreate from "./pages/ADM/Filial/Create";
import FilialUpdate from "./pages/ADM/Filial/Update";

// --> Parâmetros
import Parameters from "./pages/ADM/Parameters";
import ParametersCreate from "./pages/ADM/Parameters/Create";
import ParametersUpdate from "./pages/ADM/Parameters/Update";

// --> Perfis dos usuários
import UserProfiles from "./pages/ADM/UserProfiles";
import UserProfilesCreate from "./pages/ADM/UserProfiles/Create";
import UserProfilesUpdate from "./pages/ADM/UserProfiles/Update";

// --> Usuários
import Users from "./pages/ADM/Users";
import UsersCreate from "./pages/ADM/Users/Create";
import UsersUpdate from "./pages/ADM/Users/Update";

// --> Espacos
import Spaces from "./pages/ADM/Spaces";
import SpacesCreate from "./pages/ADM/Spaces/Create";
import SpacesUpdate from "./pages/ADM/Spaces/Update";

// --> Produtos
import Product from "./pages/ADM/Product";
import ProductCreate from "./pages/ADM/Product/Create";
import ProductUpdate from "./pages/ADM/Product/Update";

// --> Serviços
import Service from "./pages/ADM/Service";
import ServiceCreate from "./pages/ADM/Service/Create";
import ServiceUpdate from "./pages/ADM/Service/Update";

import NotAuthorizedPage from "./pages/401";

/**
 * Internal Dependencies
 */
// import RoutesList from "./pages/pages";
import NotFoundPage from "./pages/404";

/**
 * Component
 */
class Routes extends Component {
  render() {
    const { location, history } = this.props;

    let RoutesList = {
      "/sign-in": AuthSignIn,
      "/sign-up": AuthSignUp,
      // "/politica-de-privacidade": PrivacyPolicy,

      "/":
        Cookies.get("rui-auth-profile_id") == "1" ||
        Cookies.get("rui-auth-profile_id") == "4" ||
        Cookies.get("rui-auth-profile_id") == "0" ||
        Cookies.get("rui-auth-user_id") == "420"
          ? Dashboard
          : NotAuthorizedPage,

      //operacional
      "/pdv/app": PdvApp,
      "/pdv/app/pagamento/:id": PdvAppPayment,
      "/pdv/balcao/": PdvBalcao,
      "/pdv/relatorio": PDVReport,
      "/pdv/ranking": PDVRanking,
      "/pdv/show/:id": PDVShow,
      "/caixa/": Cashier,
      "/caixa/:id": Cashier,

      //financeiro
      "/lancamentos-a-receber": FlowReceive,
      "/lancamentos-a-pagar": FlowPay,
      "/lancamentos/": Flow,
      "/lancamentos/comissoes": FlowCommission,

      "/contas/:id/relatorio": BankAccountUpdate,
      "/contas": BankAccount,
      "/contas/add": BankAccountCreate,
      "/contas/edit/:id": BankAccountUpdate,

      "/cartoes-de-credito/:id/relatorio": CreditCardUpdate,
      "/cartoes-de-credito": CreditCard,
      "/cartoes-de-credito/add": CreditCardCreate,
      "/cartoes-de-credito/edit/:id": CreditCardUpdate,

      // -> CRM
      // --> Clients
      "/crm/clientes": CRMClients,
      "/crm/clientes/add": CRMClientsCreate,
      "/crm/clientes/edit/:id": CRMClientsUpdate,
      "/crm/clientes/show/:id": CRMClientsDetails,
      // --> --> schedules
      "/crm/clientes/:id/agendamentos": CRMClientsSchedules,
      "/crm/clientes/:id/calendario": CRMClientsCalendar,
      // --> schedules
      "/crm/agenda": CRMCalendar,

      // -> Report
      // --> Gerenciais
      "/relatorios/gerenciais/caixas": ReportGerencialCashier,
      "/relatorios/gerenciais/comissoes": ReportGerencialCommission,
      "/relatorios/gerenciais/servicos-realizados": ReportGerencialRealizedServices,
      "/relatorios/gerenciais/produtos-vendidos": ReportGerencialProductSold,

      //Adminstrations
      "/filiais": Filial,
      "/filiais/add": FilialCreate,
      "/filiais/edit/:id": FilialUpdate,

      "/perfis": UserProfiles,
      "/perfis/add": UserProfilesCreate,
      "/perfis/edit/:id": UserProfilesUpdate,

      "/usuarios": Users,
      "/usuarios/add": UsersCreate,
      "/usuarios/edit/:id": UsersUpdate,

      "/espacos": Spaces,
      "/espacos/add": SpacesCreate,
      "/espacos/edit/:id": SpacesUpdate,

      "/produtos": Product,
      "/produtos/add": ProductCreate,
      "/produtos/edit/:id": ProductUpdate,

      "/servicos": Service,
      "/servicos/add": ServiceCreate,
      "/servicos/edit/:id": ServiceUpdate,

      "/parametros": Parameters,
      "/parametros/add": ParametersCreate,
      "/parametros/edit/:id": ParametersUpdate,
    };

    if (Cookies.get("rui-auth-user_id") == "420") {
      let RoutesList = {
        "/sign-in": AuthSignIn,
        "/sign-up": AuthSignUp,
        // "/politica-de-privacidade": PrivacyPolicy,

        "/": Dashboard,
      };
    }

    return (
      <Switch location={location}>
        {Object.keys(RoutesList).map(path => {
          const RouteInner = RoutesList[path];

          return <Route key={path} path={path} exact render={() => <RouteInner {...this.props} />} />;
        })}

        {/* 404 */}
        <Route render={() => <NotFoundPage />} />
      </Switch>
    );
  }
}

export default Routes;
