/**
 * Styles
 */
import "./style.scss";

/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";

/**
 * Component
 */
class AuthSignInPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <div className="rui-main" style={{ position: "relative" }}>
          <div className="rui-sign align-items-center justify-content-center">
            <AsyncComponent component={() => import("./content")} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AuthSignInPage;
